
export { Stats };

import { CONFIG } from './config.js';

/* jshint -W069 */  // ['xx'] is better written in dot notation

var Stats = (function () {

	var NOT_AVAILABLE = CONFIG.STAT_NOT_AVAILABLE;
	var INVALID_STAT  = CONFIG.STAT_INVALID;

	var webReport; // reference to webReport object

	var getColumnKey = function (column) {
		if (typeof column === 'string') {
			return column;
		} else if (typeof column === 'object') {
			return column['key'];
		}
	};

	// by default, a column is aggregatable
	var getColumnAgg = function (column) {
		if (typeof column === 'object') {
			if (column.hasOwnProperty('agg')) {
				return column['agg'];
			}
		}
		return true;
	};

	// by default, a column is set for 'us'
	var getColumnWho = function (column) {
		if (typeof column === 'object') {
			if (column.hasOwnProperty('who')) {
				return column['who'];
			}
		}
		return 'us';
	};

	var getColumnFmt = function (column) {
		if (typeof column === 'object') {
			if (column.hasOwnProperty('fmt')) {
				return column['fmt'];
			}
		}
	};

	// ============== //
	// PUBLIC METHODS //
	// ============== //

	var init = function (opts) {
		webReport = opts.webReport;
	};

	// TODO: optimize so that we only pass in the columns that we need to calc
	// TODO: this is accessing global variables.  do we really need to do this?
	var calc = function (data, columns, opts) {

		if (typeof opts !== "object") {
			opts = {};
		}

		var inRally = opts.inRally ? opts.inRally : { pass: false, hit: false, dig: false };

		var statsComplete = [];

		var who = (opts.who) ? opts.who : 'us';
		var opp = (who === "us") ? "them" : "us";

		// TODO: This should work perfectly for 'us' or 'them'.  Right now there are a lot
		// of stats that are assuming 'us'.  For 'them', it should respond properly for
		// every stat.  NOT_AVAILABLE would be common.

		// TODO: maybe extract this loop?
		for (var i=0, iLen=data.length; i < iLen; i++) {

			var row = data[i];
			var stats = {};
			for (var j=0, jLen=columns.length; j < jLen; j++) {

				var columnKey = getColumnKey(columns[j]);
				var columnAgg = getColumnAgg(columns[j]);
				//var columnWho = getColumnWho(columns[j]);

				if (opts.agg & !columnAgg) {
					continue;
				}

				switch (columnKey) {
					case "player_num":
						stats["player_num"] = row['player_num'];
						if (stats["player_num"] === CONFIG.TEAM_STAT_ID) {
							stats["player_num"] = "n/a";
						}

						break;

					case 'player_name':
						stats['player_name'] = row['player_name'];
						break;

					case 'game_name':
						// TODO: don't reference global
						var game = webReport.get_xgame()[row['game_id']];
						stats['game_name']                     = game['name'];
						break;

					case 'game_id':
						stats['game_id']   = row['game_id'];
						break;

					case 'match_name':
					case 'match_id':
						var match = webReport.get_xmatch()[row['match_id']];
						var mdate;
						if (row['match_name']){
							// match_name can be manually preset; e.g., in webReport.processMatchComparisonByPlayerStats()
							stats['match_name'] = row['match_name'];
						} else if (match['venue']) {
							stats['match_name'] = match['venue'] + ', ' + match['opponent'];
						} else {
							// Touch events can have no venue specified
							mdate = new Date(match['date']);
							stats['match_name'] = match['opponent'] + ' (' + (mdate.getMonth()+1) + '/' + mdate.getDate() + '/' + mdate.getFullYear() + ')';
						}
						stats['match_id'] = row['match_id'];
						break;

					case 'team_name':

						var team = webReport.get_xteam()[row['team_id']];
						if (team) {
							stats['team_name']                    = team['name'];
						}
						break;

					case 'tournament_name':
					case 'tournament_id':
						stats['tournament_name'] = webReport.getTournamentName(row['tournament_id']);
						stats['tournament_id'] = row['tournament_id'];
						break;

					case 'match_score':
						var match_score = webReport.getMatchScore(row['match_id']);
						stats['match_score']                   = match_score.us + ' - ' + match_score.them;
						break;

					case 'game_score':
						// Since some times the game score will be desired when we have a filter
						// that prevents us from counting all of the points, we will always use
						// the pre-calculated game score
						var game_score = webReport.getScoreForGame(row['game_id']);
						stats['game_score'] = game_score.us + '&nbsp;-&nbsp;' + game_score.them;
						break;

					case 'points_for':
					case 'points_against':
						stats['points_for']     = row['points_for'];
						stats['points_against'] = row['points_against'];
						break;

					case 'games_played':
					case 'games_played_with_agg':
						stats['games_played']          = row['games_played'];
						stats['games_played_with_agg'] = row['games_played'];
						break;

					case 'points_played':
						stats['points_played']                  = row['points_played'];
						break;

					case 'points_played_setter':
						stats['points_played_setter']                  = row['points_played_setter'];
						break;

					case 'points_played_server':
						stats['points_played_server']                  = row['points_played_server'];
						break;

					case 'points_played_asst':
						stats['points_played_asst']                  = row['points_played_asst'];
						break;

					case 'serve_attempts':
					case 'serve_attempts_per_game':

						stats['serve_attempts']                 = row[who]['serve_attempts'];
						if (row['games_played'] > 0) {
							stats['serve_attempts_per_game'] = (row[who]['serve_attempts'] / row['games_played']).toFixed(1);
						} else {
							stats['serve_attempts_per_game'] = NOT_AVAILABLE;
						}
						break;

					case 'pt_score_percent':
					case 'points_scored_on_serve':
					case 'points_lost_on_serve':

						if (who === 'us') {
							stats['points_scored_on_serve'] = row['points_scored_on_serve'];
							stats['points_lost_on_serve']   = row[who]['serve_attempts'] - row['points_scored_on_serve'];
							stats['pt_score_percent']       = calcPointScorePercent(row['points_scored_on_serve'], row[who]['serve_attempts']);
						} else {
							stats['points_scored_on_serve'] = row['opponent_points_scored_on_serve'];
							stats['points_lost_on_serve']   = row["us"]['opponent_serve_attempts'] - row['opponent_points_scored_on_serve'];
							stats['pt_score_percent']       = calcPointScorePercent(row['opponent_points_scored_on_serve'], row["us"]['opponent_serve_attempts']);
						}
						break;

					case 'opponent_pt_score_percent':
						stats['pt_score_percent']              = (row['opponent_points_scored_on_serve'] / row[who]['opponent_serve_attempts'] * 100).toFixed(1) + '%';
						break;

					case 'side_out_percent':
					case 'points_scored_on_rcv':
					case 'points_lost_on_rcv':

						if (who === 'us') {
							stats['points_scored_on_rcv'] = row[who]['opponent_serve_attempts'] - row['opponent_points_scored_on_serve'];
							stats['points_lost_on_rcv']   = row['opponent_points_scored_on_serve'];
							stats['side_out_percent']     = calcSideOutPercent(row['opponent_points_scored_on_serve'], row[who]['opponent_serve_attempts']);
						} else {
							stats['points_scored_on_rcv'] = row["us"]['serve_attempts'] - row['points_scored_on_serve'];
							stats['points_lost_on_rcv']   = row['points_scored_on_serve'];
							stats['side_out_percent']     = calcSideOutPercent(row['points_scored_on_serve'], row["us"]['serve_attempts']);
						}
						break;

					case 'aces':
					case 'aces_per_game':
					case 'ace_percent':
					case '1srv':
					case '2srv':
					case '3srv':
					case 'xsrv':
					case 'serve_rating':
						// we automatically add aces for opp receive errors for "us"
						if (who === "us") {
							stats['aces']          = row[who].earned.ace;
						} else {
							stats['aces']          = row[who].earned.ace + row[opp].errors.rcv;
						}
						stats['aces_per_game'] = calcPerGame(stats['aces'], row['games_played']);
						stats['ace_percent']   = calcPercent(stats['aces'], row[who]['serve_attempts']);

						if (who === "us") {
							stats['1srv'] = row[who]['in_rally']['1srv'];
							stats['2srv'] = row[who]['in_rally']['2srv'];
							stats['3srv'] = row[who]['in_rally']['3srv'];
							stats['xsrv'] = row[who]['in_rally']['xsrv'];
							stats['serve_rating'] = calcServeRating(stats['1srv'], stats['2srv'], stats['3srv'], stats['aces'], (row[who]['errors']['srv'] + row[who]['faults']['foot']));
						} else {
							stats['1srv'] = NOT_AVAILABLE;
							stats['2srv'] = NOT_AVAILABLE;
							stats['3srv'] = NOT_AVAILABLE;
							stats['xsrv'] = NOT_AVAILABLE;
							stats['serve_rating'] = NOT_AVAILABLE;
						}

						break;


					case 'earned_ace':
					case 'earned_kill':
					case 'earned_killo':
					case 'earned_tip':
					case 'earned_dump':
					case 'earned_blck':
					case 'earned_blck_solo':
					case 'earned_blck_assist':
					case 'earned_dbh':
					case 'earned_spike':
					case 'aggregate_spike': // combines with kill/killo
					case 'earned_cnt':
					case 'errors_srv':
					case 'errors_rcv':
					case 'errors_spk':
					case 'errors_tip':
					case 'errors_dig':
					case 'errors_set':
					case 'errors_dbh':
					case 'errors_dump':
					case 'errors_blck':
					case 'errors_fbp':
					case 'errors_2br':
					case 'errors_3br':
					case 'errors_whb':
					case 'errors_cnt':
					case 'faults_fault':
					case 'faults_net':
					case 'faults_undr':
					case 'faults_foot':
					case 'faults_bra':
					case 'faults_otn':
					case 'faults_oor':
					case 'faults_bhndl':
					case 'faults_cnt':
					case 'eef_cnt':
					case 'net_eef_cnt':
					case 'lost_cnt':
					case 'earned_cnt_per_game':
					case 'errors_cnt_per_game':
					case 'faults_cnt_per_game':
					case 'lost_cnt_per_game':
					case 'eef_cnt_per_game':
					case 'ball_handling': // alias for faults_bhndl
					case 'attack_error_detail_out':
					case 'attack_error_detail_net':
					case 'attack_error_detail_blocked':
					case 'serve_error_detail_out':
					case 'serve_error_detail_net':

						stats['earned_ace']        = row[who]['earned']['ace'];
						stats['earned_kill']       = row[who]['earned']['kill'];
						stats['earned_killo']      = row[who]['earned']['killo'];
						stats['earned_tip']        = row[who]['earned']['tip'];
						stats['earned_dump']       = row[who]['earned']['dump'];
						stats['earned_dbh']        = row[who]['earned']['dbh'];
						stats['earned_spike']      = row[who]['earned']['spike'];

						// blocks cannot go straight off of 'earned' for 'us'
						if (who === 'us') {
							stats['earned_blck_solo']  = row[who]['blocks'];
							stats['earned_blck_assist'] = row[who]['block_assists'];
							if (opts.blockAssistsHalfValue) {
								stats['earned_blck']   = stats['earned_blck_solo'] + (stats['earned_blck_assist'] / 2);
							} else {
								stats['earned_blck']   = stats['earned_blck_solo'] + stats['earned_blck_assist'];
							}
							stats['earned_blck'] = stats['earned_blck'] + row['them']['attack_error_detail_blocked'];
						} else if (who === 'them') {
							stats['earned_blck']        = row['them']['earned']['blck'] + row['us']['attack_error_detail_blocked'];
							stats['earned_blck_solo']   = INVALID_STAT;
							stats['earned_blck_assist'] = INVALID_STAT;
						}

						stats['aggregate_spike']   = row[who]['earned']['spike'] + row[who]['earned']['kill'] + row[who]['earned']['killo'];

						// Since earned_blck is automatically counting up the correct
						// number, that's the only block stat we need here
						stats['earned_cnt'] = stats['aggregate_spike'] +
						                      stats['earned_ace'] +
						                      stats['earned_tip'] +
						                      stats['earned_dump'] +
						                      stats['earned_dbh'] +
						                      stats['earned_blck'];

						// TODO: cleanup
						stats['errors_srv']  = row[who]['errors']['srv'];
						stats['errors_rcv']  = row[who]['errors']['rcv'];
						stats['errors_spk']  = row[who]['errors']['spk'];

						stats['errors_tip']  = row[who]['errors']['tip'];
						stats['errors_dig']  = row[who]['errors']['dig'];
						stats['errors_set']  = row[who]['errors']['set'];
						stats['errors_dbh']  = row[who]['errors']['dbh'];
						stats['errors_dump'] = row[who]['errors']['dump'];
						stats['errors_blck'] = row[who]['errors']['blck'];
						stats['errors_fbp']  = row[who]['errors']['fbp'];
						stats['errors_2br']  = row[who]['errors']['2br'];
						stats['errors_3br']  = row[who]['errors']['3br'];
						stats['errors_whb']  = row[who]['errors']['whb'];
						stats['errors_cnt']  = row[who]['errors']['cnt'];

						stats['faults_fault'] = row[who]['faults']['fault'];
						stats['faults_net']   = row[who]['faults']['net'];
						stats['faults_undr']  = row[who]['faults']['undr'];
						stats['faults_foot']  = row[who]['faults']['foot'];
						stats['faults_bra']   = row[who]['faults']['bra'];
						stats['faults_otn']   = row[who]['faults']['otn'];
						stats['faults_cnt']   = row[who]['faults']['cnt'];

						// consolidated with "roov"
						stats['faults_oor']   = row[who]['faults']['oor'] + row[who]['faults']['roov'];

						// this has been consolidated with legacy faults "dblh" and "lift"
						stats['faults_bhndl'] = row[who]['faults']['bhndl'] +
							row[who]['faults']['dblh'] +
							row[who]['faults']['lift'];

						stats['ball_handling'] = stats['faults_bhndl'];

						stats['lost_cnt'] = stats['errors_cnt'] + stats['faults_cnt'];
						stats['eef_cnt'] = stats['earned_cnt'] - stats['errors_cnt'] - stats['faults_cnt'];

						// opponent counts
						stats['their_earned_cnt'] = row['them']['earned']['cnt'];
						stats['their_errors_cnt'] = row['them']['errors']['cnt'];
						stats['their_faults_cnt'] = row['them']['faults']['cnt'];
						stats['their_eef_cnt'] = stats['their_earned_cnt'] - stats['their_errors_cnt'] - stats['their_faults_cnt'];

						stats['net_eef_cnt'] = stats['eef_cnt'] - stats['their_eef_cnt'];

						if (row['games_played'] > 0) {
							stats['earned_cnt_per_game'] = (row[who]['earned']['cnt'] / row['games_played']).toFixed(1);
							stats['errors_cnt_per_game'] = (row[who]['errors']['cnt'] / row['games_played']).toFixed(1);
							stats['faults_cnt_per_game'] = (row[who]['faults']['cnt'] / row['games_played']).toFixed(1);
							stats['lost_cnt_per_game'] = ((row[who]['errors']['cnt'] + row[who]['faults']['cnt']) / row['games_played']).toFixed(1);
							stats['eef_cnt_per_game'] = (stats['eef_cnt'] / row['games_played']).toFixed(1);
						} else {
							stats['earned_cnt_per_game'] = NOT_AVAILABLE;
							stats['errors_cnt_per_game'] = NOT_AVAILABLE;
							stats['faults_cnt_per_game'] = NOT_AVAILABLE;
							stats['lost_cnt_per_game'] = NOT_AVAILABLE;
							stats['eef_cnt_per_game'] = NOT_AVAILABLE;
						}

						stats['attack_error_detail_out'] = row[who]['attack_error_detail_out'];
						stats['attack_error_detail_net'] = row[who]['attack_error_detail_net'];
						stats['attack_error_detail_blocked'] = row[who]['attack_error_detail_blocked'];
						stats['serve_error_detail_out'] = row[who]['serve_error_detail_out'];
						stats['serve_error_detail_net'] = row[who]['serve_error_detail_net'];

						break;

					case 'serve_errors':
					case 'serve_errors_per_game':
					case 'serve_percent':
					case 'serve_error_percent':
						var serve_errors = row[who]['errors']['srv'] + row[who]['faults']['foot'];

						stats['serve_errors'] = serve_errors;

						if (row['games_played'] > 0) {
							stats['serve_errors_per_game'] = (serve_errors / row['games_played']).toFixed(1);
						} else {
							stats['serve_errors_per_game'] = NOT_AVAILABLE;
						}

						if (row[who]['serve_attempts'] > 0) {
							stats['serve_error_percent'] = calcPercent(serve_errors, row[who]['serve_attempts']);
							stats['serve_percent']       = calcPercent(row[who]['serve_attempts'] - serve_errors, row[who]['serve_attempts']);
						} else {
							stats['serve_error_percent'] = NOT_AVAILABLE;
							stats['serve_percent']       = NOT_AVAILABLE;
						}
						break;

					case 'serve_net_points':
					case 'serve_net_points_per_game':
						stats['serve_net_points']              = row[who]['earned']['ace'] - row[who]['errors']['srv'];
						if (row['games_played'] > 0) {
							stats['serve_net_points_per_game']     = (stats['serve_net_points'] / row['games_played']).toFixed(1);
						} else {
							stats['serve_net_points_per_game'] = NOT_AVAILABLE;
						}
						break;

					case 'serve_rcv':
					case 'serve_rcv_success':
					case 'serve_rcv_success_percent':
					case 'serve_rcv_errors':
					case 'serve_rcv_errors_per_game':
					case 'serve_rcv_error_percent':
					case 'serve_rcv_error_percent_by_player':
					case 'pass_rating':
					case 'pass_attempts':
					case 'pass_attempts_per_game':
					case 'pass_successes':
					case 'pass_errors':
					case 'pass_error_percent':
					case '0pass':
					case '1pass':
					case '2pass':
					case '3pass':
					case 'xpass':
					case '3pass_percent':
					case 'fbp_rating':
					case 'fbp_attempts':
					case 'fbp_attempts_per_game':
					case 'fbp_successes':
					case 'fbp_errors':
					case 'fbp_error_percent':
					case '0fbp':
					case '1fbp':
					case '2fbp':
					case '3fbp':
					case 'xfbp':
					case '3fbp_percent':
					case 'first_ball_side_out_percent':

						// TODO: move to function
						stats['serve_rcv_errors']          = row[who].errors.rcv + row[opp].earned.ace + row[who].opponent_derived_rcv_errors;
						stats['serve_rcv_errors_per_game'] = calcPerGame(stats.serve_rcv_errors, row['games_played']);


						var isAggregate;
						// First figure out if we're handling by player, or by aggregate
						if (row[who]['opponent_serve_attempts'] > 0) {
							stats['serve_rcv'] = row[who]['opponent_serve_attempts'] - row[who]['opponent_serve_errors']; // no player granularity
							isAggregate = true;
						} else {
							stats['serve_rcv'] = NOT_AVAILABLE;
							isAggregate = false;
						}

						// pass stats are only available for "us"
						var numPassStats = 0;
						if (inRally.pass && who === "us") {

							// receive pass stats
							stats['0pass'] = row[who]['in_rally']['0pass'];
							stats['1pass'] = row[who]['in_rally']['1pass'];
							stats['2pass'] = row[who]['in_rally']['2pass'];
							stats['3pass'] = row[who]['in_rally']['3pass'];
							stats['xpass'] = row[who]['in_rally']['xpass'];
							var passes = stats['0pass'] + stats['1pass'] + stats['2pass'] + stats['3pass'] + stats['xpass'];
							numPassStats = passes;

							// pass errors no longer include 0passes
							stats['pass_errors'] = stats['serve_rcv_errors'];

							stats['pass_attempts'] = stats['serve_rcv_errors'] + stats['0pass'] + stats['1pass'] + stats['2pass'] +
								stats['3pass'] + stats['xpass'];
							stats['pass_attempts_per_game'] = calcPerGame(stats['pass_attempts'],  row['games_played']);
							var passAttemptsRatedOnly = stats['serve_rcv_errors'] + stats['0pass'] + stats['1pass'] + stats['2pass'] +
								stats['3pass'];

							// if we want to show serve_rcv, this is what we would use
							if (row[who]['x_serves_received']) {
								//stats['serve_rcv'] = row[who]['x_serves_received'];
							}

							stats['pass_successes'] = passes;

							stats['pass_rating']        = calcPassRating(
								stats['1pass'], stats['2pass'], stats['3pass'], passAttemptsRatedOnly, stats['0pass']
							);
							stats['pass_error_percent'] = calcPassErrorPercent(stats['pass_errors'], stats['pass_attempts']);

							stats['3pass_percent']      = calcPercent(stats['3pass'], stats['pass_attempts']);

							stats['passes/game']        = calcPerGame(stats['pass_successes'], row['games_played']);
							stats['pass_errors/game']   = calcPerGame(stats['pass_errors'],    row['games_played']);
							stats['perfect/game']       = calcPerGame(stats['3pass'],          row['games_played']);


							// freeball pass stats
							stats['0fbp'] = row[who]['in_rally']['0fbp'];
							stats['1fbp'] = row[who]['in_rally']['1fbp'];
							stats['2fbp'] = row[who]['in_rally']['2fbp'];
							stats['3fbp'] = row[who]['in_rally']['3fbp'];
							stats['xfbp'] = row[who]['in_rally']['xfbp'];
							var fbpasses = stats['0fbp'] + stats['1fbp'] + stats['2fbp'] + stats['3fbp'] + stats['xfbp'];
							numPassStats += fbpasses;

							// pass errors no longer include 0fbp
							stats['fbp_errors'] = row[who]['errors']['fbp'];

							stats['fbp_attempts'] = stats['0fbp'] + stats['1fbp'] + stats['2fbp'] + stats['3fbp'] + stats['xfbp'] +
								row[who]['errors']['fbp'];
							stats['fbp_attempts_per_game'] = calcPerGame(stats['fbp_attempts'],  row['games_played']);
							var fbpAttemptsRatedOnly =  stats['0fbp'] + stats['1fbp'] + stats['2fbp'] + stats['3fbp'] +
								row[who]['errors']['fbp'];

							stats['fbp_successes'] = fbpasses;

							stats['fbp_rating']        = calcPassRating(
								stats['1fbp'], stats['2fbp'], stats['3fbp'], fbpAttemptsRatedOnly, stats['0fbp']
							);
							stats['fbp_error_percent'] = calcPassErrorPercent(stats['fbp_errors'], stats['fbp_attempts']);

							stats['3fbp_percent']      = calcPercent(stats['3fbp'], stats['fbp_attempts']);

							stats['fbp/game']          = calcPerGame(stats['fbp_successes'], row['games_played']);
							stats['fbp_errors/game']   = calcPerGame(stats['fbp_errors'],    row['games_played']);
							stats['perfect_fbp/game']  = calcPerGame(stats['3fbp'],          row['games_played']);

							stats['first_ball_side_out_percent'] = ((row[who]['first_ball_side_out'] / numPassStats) * 100).toFixed(1) + "%";
							if (stats['first_ball_side_out_percent'] === 'NaN%' || stats['first_ball_side_out_percent'] === 'Infinity%'){
								stats['first_ball_side_out_percent'] = NOT_AVAILABLE;
							}

						} else {
							// if we want to show serve_rcv, this is what we would use
							//stats['serve_rcv'] = stats['serve_rcv_errors'];

							stats['pass_attempts']          = NOT_AVAILABLE;
							stats['pass_attempts_per_game'] = NOT_AVAILABLE;
							stats['pass_successes']         = NOT_AVAILABLE;
							stats['0pass']                  = NOT_AVAILABLE;
							stats['1pass']                  = NOT_AVAILABLE;
							stats['2pass']                  = NOT_AVAILABLE;
							stats['3pass']                  = NOT_AVAILABLE;
							stats['xpass']                  = NOT_AVAILABLE;
							stats['3pass_percent']          = NOT_AVAILABLE;
							stats['passes/game']            = NOT_AVAILABLE;
							stats['perfect/game']           = NOT_AVAILABLE;
							stats['fbp_rating']             = NOT_AVAILABLE;
							stats['fbp_attempts']           = NOT_AVAILABLE;
							stats['fbp_attempts_per_game']  = NOT_AVAILABLE;
							stats['fbp_successes']          = NOT_AVAILABLE;
							stats['0fbp']                   = NOT_AVAILABLE;
							stats['1fbp']                   = NOT_AVAILABLE;
							stats['2fbp']                   = NOT_AVAILABLE;
							stats['3fbp']                   = NOT_AVAILABLE;
							stats['xfbp']                   = NOT_AVAILABLE;
							stats['3fbp_percent']           = NOT_AVAILABLE;
							stats['fbp/game']               = NOT_AVAILABLE;
							stats['perfect_fbp/game']       = NOT_AVAILABLE;
							stats['fbp_errors']             = NOT_AVAILABLE;
							stats['fbp_error_percent']      = NOT_AVAILABLE;
							stats['fbp_errors/game']        = NOT_AVAILABLE;
							stats['first_ball_side_out_percent'] = NOT_AVAILABLE;

							// these will use serve receive errors to calculate
							stats['pass_errors']          = stats['serve_rcv_errors'];
							stats['pass_error_percent']   = stats['serve_rcv_error_percent'];
							stats['pass_errors/game']     = stats['serve_rcv_errors_per_game'];

							// for opponent pass rating, calculate our team's serve rating first (excluding errors)
							if (who === "them") {
								var our_serve_rating = calcServeRating(row['us']['in_rally']['1srv'], row['us']['in_rally']['2srv'], row['us']['in_rally']['3srv'], row['us']['earned']['ace'], 0);
								if (!isNaN(our_serve_rating)) {
									stats['pass_rating'] = (4 - our_serve_rating).toFixed(2);
								} else {
									stats['pass_rating'] = NOT_AVAILABLE;
								}
							} else {
								stats['pass_rating'] = NOT_AVAILABLE;
							}
						}

						if (isAggregate) {
							stats['serve_rcv_success']         = stats['serve_rcv'] - stats['pass_errors'];
							stats['serve_rcv_success_percent'] = calcPercent(stats['serve_rcv_success'], stats['serve_rcv']);
							stats['serve_rcv_error_percent']   = calcPercent(stats['serve_rcv_errors'], stats['serve_rcv']);
						} else {
							if (numPassStats > 0) {
								stats['serve_rcv_success']         = stats['pass_attempts'] - stats['pass_errors'];
								stats['serve_rcv_success_percent'] = calcPercent(stats['serve_rcv_success'], stats['pass_attempts']);
								stats['serve_rcv_error_percent']   = calcPercent(stats['serve_rcv_errors'], stats['pass_attempts']);

							} else {
								stats['serve_rcv_success']         = NOT_AVAILABLE;
								stats['serve_rcv_success_percent'] = NOT_AVAILABLE;
								stats['serve_rcv_error_percent']   = NOT_AVAILABLE;
							}
						}

						break;

					case 'fbrip':
					case 'fbr_errors':
						if (who === "us"){
							stats['fbrip'] = row[who]['in_rally']['fbrip'];
							stats['fbr_errors'] = row[who]['errors']['2br'] + row[who]['errors']['3br'];
						} else {
							stats['fbrip'] = NOT_AVAILABLE;
							stats['fbr_errors'] = NOT_AVAILABLE;
						}
						break;

					case 'setin_rating':
					case 'setin_attempts':
					case 'setin_successes':
					case 'setin_errors':
					case 'setin_error_percent':
					case '1setin':
					case '2setin':
					case '3setin':
					case '3setin_percent':

						if (who === "us") {
							stats['1setin'] = row[who]['in_rally']['1setin'];
							stats['2setin'] = row[who]['in_rally']['2setin'];
							stats['3setin'] = row[who]['in_rally']['3setin'];
							stats['setin_errors'] = row[who]['errors']['setin'];
							let setins = stats['1setin'] + stats['2setin'] + stats['3setin'];

							stats['setin_attempts'] = stats['setin_errors'] + stats['1setin'] + stats['2setin'] + stats['3setin'];

							stats['setin_successes'] = setins;

							stats['setin_rating']      = calcPassRating(stats['1setin'], stats['2setin'], stats['3setin'], stats['setin_attempts']);
							stats['setin_error_percent'] = calcPassErrorPercent(stats['setin_errors'], stats['setin_attempts']);

							stats['3setin_percent']      = calcPercent(stats['3setin'], stats['setin_attempts']);

							stats['setin/game']          = calcPerGame(stats['setin_successes'], row['games_played']);
							stats['setin_errors/game']   = calcPerGame(stats['setin_errors'],    row['games_played']);
							stats['perfect_setin/game']  = calcPerGame(stats['3setin'],          row['games_played']);

						} else {
							stats['setin_rating']           = NOT_AVAILABLE;
							stats['setin_attempts']         = NOT_AVAILABLE;
							stats['setin_successes']        = NOT_AVAILABLE;
							stats['1setin']                 = NOT_AVAILABLE;
							stats['2setin']                 = NOT_AVAILABLE;
							stats['3setin']                 = NOT_AVAILABLE;
							stats['3setin_percent']         = NOT_AVAILABLE;
							stats['setin/game']             = NOT_AVAILABLE;
							stats['perfect_setin/game']     = NOT_AVAILABLE;
							stats['setin_errors']           = NOT_AVAILABLE;
							stats['setin_error_percent']    = NOT_AVAILABLE;
							stats['setin_errors/game']      = NOT_AVAILABLE;
						}
						break;

					case 'setout_rating':
					case 'setout_attempts':
					case 'setout_successes':
					case 'setout_errors':
					case 'setout_error_percent':
					case '1setout':
					case '2setout':
					case '3setout':
					case '3setout_percent':

						if (who === "us") {
							stats['1setout'] = row[who]['in_rally']['1setout'];
							stats['2setout'] = row[who]['in_rally']['2setout'];
							stats['3setout'] = row[who]['in_rally']['3setout'];
							stats['setout_errors'] = row[who]['errors']['setout'];
							let setouts = stats['1setout'] + stats['2setout'] + stats['3setout'];

							stats['setout_attempts'] = stats['setout_errors'] + stats['1setout'] + stats['2setout'] + stats['3setout'];

							stats['setout_successes'] = setouts;

							stats['setout_rating']      = calcPassRating(stats['1setout'], stats['2setout'], stats['3setout'], stats['setout_attempts']);
							stats['setout_error_percent'] = calcPassErrorPercent(stats['setout_errors'], stats['setout_attempts']);

							stats['3setout_percent']      = calcPercent(stats['3setout'], stats['setout_attempts']);

							stats['setout/game']          = calcPerGame(stats['setout_successes'], row['games_played']);
							stats['setout_errors/game']   = calcPerGame(stats['setout_errors'],    row['games_played']);
							stats['perfect_setout/game']  = calcPerGame(stats['3setout'],          row['games_played']);

						} else {
							stats['setout_rating']           = NOT_AVAILABLE;
							stats['setout_attempts']         = NOT_AVAILABLE;
							stats['setout_successes']        = NOT_AVAILABLE;
							stats['1setout']                 = NOT_AVAILABLE;
							stats['2setout']                 = NOT_AVAILABLE;
							stats['3setout']                 = NOT_AVAILABLE;
							stats['3setout_percent']         = NOT_AVAILABLE;
							stats['setout/game']             = NOT_AVAILABLE;
							stats['perfect_setout/game']     = NOT_AVAILABLE;
							stats['setout_errors']           = NOT_AVAILABLE;
							stats['setout_error_percent']    = NOT_AVAILABLE;
							stats['setout_errors/game']      = NOT_AVAILABLE;
						}
						break;

					case 'touch':
					case 'settch':
					case 'srvtch':
					case 'digtch':
					case 'blktch':
					case 'atktch':
					case 'srtch':
					case 'fbptch':
						if (who === "us"){
							stats['touch']  = row[who]['touch']['touch'];
							stats['settch'] = row[who]['touch']['settch'];
							stats['srvtch'] = row[who]['touch']['srvtch'];
							stats['digtch'] = row[who]['touch']['digtch'];
							stats['blktch'] = row[who]['touch']['blktch'];
							stats['atktch'] = row[who]['touch']['atktch'];
							stats['srtch']  = row[who]['touch']['srtch'];
							stats['fbptch'] = row[who]['touch']['fbptch'];
						} else {
							stats['touch']  = NOT_AVAILABLE;
							stats['settch'] = NOT_AVAILABLE;
							stats['srvtch'] = NOT_AVAILABLE;
							stats['digtch'] = NOT_AVAILABLE;
							stats['blktch'] = NOT_AVAILABLE;
							stats['atktch'] = NOT_AVAILABLE;
							stats['srtch']  = NOT_AVAILABLE;
							stats['fbptch'] = NOT_AVAILABLE;
						}
						break;

					case 'combined_kills':
					case 'combined_kills_per_game':
					case 'combined_kill_errors':
					case 'combined_kill_errors_minus_block_details':
					case 'combined_kill_errors_per_game':
					case 'combined_kill_error_percent':
					case 'combined_kill_attempts':
					case 'combined_kill_attempts_per_game':
					case 'combined_kill_attempts_calculated':
					case 'combined_kill_percent':
					case 'attack_net_points':
					case 'attack_net_points_per_game':
					case 'hits_still_in_play':
					case 'hitting_efficiency':
					case 'games_played_as_setter':
					case 'games_played_as_non_setter':
					case 'assists': // assists calculations have related data
					case 'assists_per_game':
					case 'assists_as_setter':
					case 'assists_as_setter_per_game':
					case 'assists_as_setter_percent':
					case 'assists_as_non_setter':
					case 'assists_as_non_setter_per_game':
					case 'assists_as_non_setter_percent':
					case 'set_attempts':
					case 'setting_errors':
					case 'setting_errors_if_setter': // specifically verifies player's role was setter
					case 'htatt_attempt_from_setter':
					case 'htatt_attempt_not_from_setter':


						// TODO: we should check to be sure that the number of attempted
						// kills is at least the number of calculated attempted kills.
						stats['combined_kills'] = calcKills({
							kills          : row[who]['earned']['kill'],
							killOthers     : row[who]['earned']['killo'],
							tips           : row[who]['earned']['tip'],
							downBallHits   : row[who]['earned']['dbh'],
							spikes         : row[who]['earned']['spike'],
							dumps          : row[who]['earned']['dump'],
							oppBlockErrors : row[opp]['errors']['blck'],
							oppDigErrors   : row[opp]['errors']['dig'],
							oppDerived     : row[who]["opponent_derived_kills"]
						});

						stats['combined_kill_errors'] = calcKillErrors({
							spikeErrors         : row[who]['errors']['spk'],
							tipErrors           : row[who]['errors']['tip'],
							downBallHitErrors   : row[who]['errors']['dbh'],
							dumpErrors          : row[who]['errors']['dump'],
							backRowAttackFaults : row[who]['faults']['bra'],
						});

						// used in Point Trees so block details aren't double counted as Block and Opp Hit Err in "Them" column
						stats['combined_kill_errors_minus_block_details'] = stats['combined_kill_errors'] - row[who]['attack_error_detail_blocked'];

						stats['attack_net_points'] = stats['combined_kills'] - stats['combined_kill_errors'];



						// The actual aggregation of this takes place in processStatsIteration
						// so we can account for manual hit attempt entries
						if (who === "us") {
							stats['hits_still_in_play']     = row[who]['in_rally']['htatt'];
							// This is used for verification against any manual entries
							stats['combined_kill_attempts_calculated'] = stats['combined_kills'] + stats['combined_kill_errors'] + stats['hits_still_in_play'];

							if (row[who]['edit']['combined_kill_attempts']) {
								stats['combined_kill_attempts'] = row[who]['edit']['combined_kill_attempts'];
								stats['hits_still_in_play'] = stats['combined_kill_attempts'] - stats['combined_kills'] - stats['combined_kill_errors'];
							} else {
								stats['combined_kill_attempts'] = stats['combined_kill_attempts_calculated'];
							}
						} else {
							// since we don't track this for the opponent, we have to put
							// this together:
							// Our Digs + Blocks Still in Play = Their Hits Still in Play.
							var opponentDigs;
							if (row[opp]['edit']['digs']) {
								opponentDigs = row[opp]['edit']['digs'];
							} else {
								opponentDigs = row[opp]['in_rally']['dgatt'];
							}
							stats['hits_still_in_play'] = row[opp]['in_rally']['blksip'] + opponentDigs;
							stats['combined_kill_attempts_calculated'] = stats['combined_kills'] + stats['combined_kill_errors'] + stats['hits_still_in_play'];
							stats['combined_kill_attempts'] = stats['combined_kill_attempts_calculated'];
						}


						stats['combined_kills_per_game']         = calcPerGame(stats['combined_kills'],         row['games_played']);
						stats['combined_kill_errors_per_game']   = calcPerGame(stats['combined_kill_errors'],   row['games_played']);
						stats['attack_net_points_per_game']      = calcPerGame(stats['attack_net_points'],      row['games_played']);
						stats['combined_kill_attempts_per_game'] = calcPerGame(stats['combined_kill_attempts'], row['games_played']);

						stats['hitting_efficiency']    = calcHittingEfficiency({
							kills       : stats['combined_kills'],
							killErrors  : stats['combined_kill_errors'],
							killAttempts: stats['combined_kill_attempts']
						});

						stats['combined_kill_percent'] = calcPercent(stats['combined_kills'], stats['combined_kill_attempts']);
						stats['combined_kill_error_percent'] = calcPercent(stats['combined_kill_errors'], stats['combined_kill_attempts']);


						if (who === "us") {
							var games_played               = row['games_played'];
							var games_played_as_setter     = row['games_played_as_setter'];
							var games_played_as_non_setter;

							if (row['games_played_as_non_setter']) {
								// this is explicitly passed for per-player game aggregates
								games_played_as_non_setter = row['games_played_as_non_setter'];
							} else {
								games_played_as_non_setter = games_played - games_played_as_setter;
							}
							stats['assists_as_setter']     = row[who]['assists_as_setter'];
							stats['assists_as_non_setter'] = row[who]['assists_as_non_setter'];
							stats['assists']               = stats['assists_as_setter'] + stats['assists_as_non_setter'];
							stats["set_attempts"]          = row[who]["set_attempts"];

							if (stats['assists'] === 0){
								stats['assists_as_setter_percent']     = NOT_AVAILABLE;
								stats['assists_as_non_setter_percent'] = NOT_AVAILABLE;
							} else {
								stats['assists_as_setter_percent']     = ((stats['assists_as_setter'] / stats['assists']) * 100).toFixed(1) + "%";
								stats['assists_as_non_setter_percent'] = ((stats['assists_as_non_setter'] / stats['assists']) * 100).toFixed(1) + "%";
							}

							stats['games_played_as_setter']     = games_played_as_setter;
							stats['games_played_as_non_setter'] = games_played_as_non_setter;

							if (games_played_as_setter > 0) {
								stats['assists_as_setter_if_setter'] = stats['assists_as_setter'];
								stats['assists_as_setter_per_game']  = (stats['assists_as_setter'] / games_played_as_setter).toFixed(1);
								stats['setting_errors_if_setter']    = row[who]['errors']['set'];
							} else {
								stats['assists_as_setter_if_setter'] = NOT_AVAILABLE;
								stats['assists_as_setter_per_game']  = NOT_AVAILABLE;
								stats['setting_errors_if_setter']    = NOT_AVAILABLE;
							}

							if (stats['assists_as_non_setter']) {
								stats['assists_as_non_setter_per_game']   = (stats['assists_as_non_setter'] / games_played_as_non_setter).toFixed(1);
							} else {
								stats['assists_as_non_setter_per_game']   = NOT_AVAILABLE;
							}

							stats['htatt_attempt_not_from_setter'] = row[who]['htatt_noset'] + row[who]['htatt_noset_specified'];
							stats['htatt_attempt_from_setter'] = row[who]['in_rally']['htatt'] - stats['htatt_attempt_not_from_setter'];

						} else {
							// their assists are just all of their kills worthy of one
							stats['assists'] = calcKills({
								kills          : row[who]['earned']['kill'],
								killOthers     : row[who]['earned']['killo'],
								tips           : row[who]['earned']['tip'],
								downBallHits   : row[who]['earned']['dbh'],
								spikes         : row[who]['earned']['spike'],
								dumps          : row[who]['earned']['dump'],
								oppBlockErrors : row[opp]['errors']['blck'],
								oppDigErrors   : row[opp]['errors']['dig']
							});

							// their set attempts
							stats["set_attempts"] = stats['assists'] +
								stats['hits_still_in_play']          +
								row[who]['errors']['spk']            +
								row[who]['errors']['tip']            +
								row[who]['errors']['dbh'];

						}

						stats['assists_per_game'] = (stats['assists'] / row['games_played']).toFixed(1);
						stats['setting_errors']   = row[who]['errors']['set'];

						break;

					case 'blocks':
					case 'blocks_per_game':
					case 'block_solos':
					case 'block_solos_per_game':
					case 'block_assists':
					case 'block_assists_per_game':
					case 'block_errors':
					case 'block_errors_per_game':
					case 'blocks_still_in_play':
					case 'blocks_still_in_play_per_game':
					case 'block_attempts':
					case 'block_attempts_per_game':
					case 'net_blocks':
					case 'net_blocks_per_game':
					case 'block_percent':
					case 'block_error_percent':

						stats['block_solos']   = row[who]['blocks'];
						stats['block_assists'] = row[who]['block_assists'];
						stats['block_errors']  = row[who]['errors']['blck'];

						stats["blocks"] = calcBlocks({
							blockSolos  : stats["block_solos"],
							blockAssists: stats["block_assists"]
						}, {
							blockAssistsHalfValue: opts.blockAssistsHalfValue
						});

						if (row[who]['edit']['block_attempts']) {
							stats['block_attempts'] = row['us']['edit']['block_attempts'];
							stats['blocks_still_in_play'] = stats['block_attempts'] - stats['block_solos'] - stats['block_errors'] - stats['block_assists'];
						} else {
							stats['block_attempts'] = calcBlockAttempts({
								blockSolos        : stats["block_solos"],
								blockAssists      : stats["block_assists"],
								blocksStillInPlay : stats["blocks_still_in_play"],
								blockErrors       : stats["block_errors"]
							});
							stats['blocks_still_in_play']          = row[who]['in_rally']['blksip'];
						}

						stats['block_percent'] = calcBlockPercent({
							blockSolos    : stats['block_solos'],
							blockAssists  : stats['block_assists'],
							blockAttempts : stats['block_attempts']
						});

						stats['net_blocks'] = stats['blocks'] - stats['block_errors'];

						// TODO: need to add inRally.block flag
						stats['blocks_still_in_play_per_game'] = calcPerGame(stats['blocks_still_in_play'], row['games_played']);
						stats['block_error_percent']           = calcPercent(stats['block_errors'], stats['block_attempts']);

						stats['block_attempts_per_game']       = calcPerGame(stats['block_attempts'], row['games_played']);
						stats['blocks_per_game']               = calcPerGame(stats['blocks'],         row['games_played']);
						stats['block_solos_per_game']          = calcPerGame(stats['block_solos'],    row['games_played']);
						stats['block_assists_per_game']        = calcPerGame(stats['block_assists'],  row['games_played']);
						stats['block_errors_per_game']         = calcPerGame(stats['block_errors'],   row['games_played']);
						stats['net_blocks_per_game']           = calcPerGame(stats['net_blocks'],     row['games_played']);

						break;

					case 'digs':
					case 'digs_per_game':
					case 'dig_attempts':
					case 'dig_attempts_per_game':
					case 'dig_errors':
					case 'dig_errors_per_game':
					case 'dig_error_percent':
					case 'dig_percent':
					case 'kill_to_dig_percent':
					case 'attack_attempt_to_dig_percent':


						stats['dig_errors']                    = row[who]['errors']['dig'];

						if (inRally.dig && who === "us") {
							stats['digs']                          = row[who]['in_rally']['dgatt'];

							if (row[who]['edit']['digs']) {
								stats['digs'] = row[who]['edit']['digs'];
							}

							stats['dig_attempts']          = calcDigAttempts(stats['digs'], stats['dig_errors']);
							stats['dig_attempts_per_game'] = calcDigAttemptsPerGame(stats['dig_attempts'], row['games_played']);
							stats['dig_percent']           = calcDigPercent(stats['digs'], stats['dig_attempts']);

							stats['kill_to_dig_percent'] = ((row[who]['kill_to_dig'] / stats['digs']) * 100).toFixed(1) + "%";
							if (stats['kill_to_dig_percent'] === 'NaN%' || stats['kill_to_dig_percent'] === 'Infinity%'){
								stats['kill_to_dig_percent'] = NOT_AVAILABLE;
							}
							stats['attack_attempt_to_dig_percent'] = ((row[who]['attack_attempt_to_dig'] / stats['digs']) * 100).toFixed(1) + "%";
							if (stats['attack_attempt_to_dig_percent'] === 'NaN%' || stats['attack_attempt_to_dig_percent'] === 'Infinity%'){
								stats['attack_attempt_to_dig_percent'] = NOT_AVAILABLE;
							}
						} else {
							stats['digs']                          = NOT_AVAILABLE;
							stats['dig_attempts']                  = NOT_AVAILABLE;
							stats['dig_attempts_per_game']         = NOT_AVAILABLE;
							stats['dig_percent']                   = NOT_AVAILABLE;
							stats['kill_to_dig_percent']           = NOT_AVAILABLE;
							stats['attack_attempt_to_dig_percent'] = NOT_AVAILABLE;
						}

						if (row['games_played'] > 0) {
							stats['dig_errors_per_game']           = (stats['dig_errors'] / row['games_played']).toFixed(1);
							if (stats['digs'] !== NOT_AVAILABLE) {
								stats['digs_per_game']                 = (stats['digs'] / row['games_played']).toFixed(1);
							} else {
								stats['digs_per_game']                 = NOT_AVAILABLE;
							}
						} else {
							stats['dig_errors_per_game']           = NOT_AVAILABLE;
							stats['digs_per_game']                 = NOT_AVAILABLE;
						}

						if (stats['dig_attempts'] > 0) {
							stats['dig_error_percent'] = calcPercent(stats['dig_errors'], stats['dig_attempts']);
						} else {
							stats['dig_error_percent'] = NOT_AVAILABLE;
						}

						break;

					case 'rotation':
						stats['rotation'] = row['rotation'];
						break;

					case 'rot_serv_player':
						stats['rot_serv_player']         = row['rot_serv_player'];
						break;

					case 'rot_serv':
					case 'rot_serv_them':
						stats['rot_serv']         = row['rot_serv'];
						stats['rot_serv_them']    = row['rot_serv'];
						break;

					case 'rotation_log':
						stats['rotation_log'] = row['rotation_log'];
						break;
					case 'rally_log':
						stats['rally_log'] = row['rally_log'];
						break;
					case 'stage_log':
						stats['stage_log'] = row['stage_log'];
						break;
					case 'player_log':
						if (row['playerteam'] === 'them'){
							stats['player_log'] = '(Opponent)';
						} else {
							stats['player_log'] = '#' + row['playernum'] + ' ' + row['playername'];
						}
						break;
					case 'action_type_log':
						stats['action_type_log'] = row['action_type_log'];
						break;
					case 'action_log':
						stats['action_log'] = row['action_log'];
						break;
					case 'assist_log':
						if (row['asstnum'] === null || row['asstname'] === null) {
							stats['assist_log'] = NOT_AVAILABLE;
						} else {
							stats['assist_log'] = '#' + row['asstnum'] + ' - ' + row['asstname'];
						}
						break;
					case 'point_to_log':
						stats['point_to_log'] = row['point_to_log'];
						break;
					case 'score_log':
						stats['score_log'] = row['score_log'];
						break;
					case 'timestamp_log':
						stats['timestamp_log'] = row['timestamp_log'];
						break;

					case 'us_adjustment':
						stats['us_adjustment'] = row['us_adjustment'];
						break;

					case 'them_adjustment':
						stats['them_adjustment'] = row['them_adjustment'];
						break;

					// Stats below added with 2019 Coach redesign - stats not calculated here are calculated in coach.js
					case 'points_earned':
					case 'points_lost':
					case 'points_net':
					case 'percent_of_team_points_earned':
					case 'percent_of_team_points_lost':
					case 'percent_of_team_points_net':
					case 'percent_of_team_serve_attempts':
					case 'percent_of_team_rcv_attempts':
					case 'percent_of_team_kill_attempts':
					case 'percent_of_team_dig_attempts':
					case 'percent_of_team_set_attempts':
					case 'percent_of_team_fbp_attempts':
					case 'percent_of_team_block_attempts':
					case 'games_played_percent':
					case 'points_played_percent':
					case 'points_played_per_game':
						// points_scored_on_serve + points_scored_on_rcv
						stats['points_earned'] = row['points_scored_on_serve'] + (row[who]['opponent_serve_attempts'] - row['opponent_points_scored_on_serve']);

						// points_lost_on_serve + points_lost_on_rcv
						stats['points_lost'] = (row[who]['serve_attempts'] - row['points_scored_on_serve']) + row['opponent_points_scored_on_serve'];

						stats['points_net'] = stats['points_earned'] - stats['points_lost'];

						stats['points_played_per_game'] = calcPerGame(stats['points_played'], stats['games_played']);
						break;

				}
			}

			// TODO: inprog: so, how to handle for one row of data going to two different tables?
			statsComplete.push(stats);
		}

		return statsComplete;

	};

	var calcPercent = function (a, b) {
		if (b > 0) {
			return (a / b * 100).toFixed(1) + '%';
		} else {
			return NOT_AVAILABLE;
		}
	};

	var calcPerGame = function (a, b) {
		if (b > 0) {
			return (a / b).toFixed(1);
		} else {
			return NOT_AVAILABLE;
		}
	};

	var calcSideOutPercent = function (oppPointsScoredOnServe, oppServeAttempts) {
		if (oppServeAttempts > 0) {
			return ((oppServeAttempts - oppPointsScoredOnServe) / oppServeAttempts * 100).toFixed(1) + '%';
		} else {
			return NOT_AVAILABLE;
		}
	};

	var calcPointScorePercent = function (pointsScoredOnServe, serveAttempts) {
		if (serveAttempts > 0) {
			return (pointsScoredOnServe / serveAttempts * 100).toFixed(1) + '%';
		} else {
			return NOT_AVAILABLE;
		}
	};

	var calcPassRating = function (pass1, pass2, pass3, passAttempts, pass0=0) {
		if (passAttempts > 0) {
			return (((pass0 * 0.5) + pass1 + (pass2 * 2) + (pass3 * 3)) / passAttempts).toFixed(2);
		} else {
			return NOT_AVAILABLE;
		}
	};

	var calcServeRating = function (srv1, srv2, srv3, aces, errors) {
		var numRatedServes = srv1 + srv2 + srv3;

		if (numRatedServes > 0) {
			var ratedServeAttempts = srv1 + srv2 + srv3 + aces + errors;
			return ((srv1 + (srv2 * 2) + (srv3 * 3) + (aces * 4)) / ratedServeAttempts).toFixed(2);
		} else {
			return NOT_AVAILABLE;
		}
	};

	var calcPassErrorPercent = function (passErrors, passAttempts) {
		return calcPercent(passErrors, passAttempts);
	};

	var calcKills = function (data) {
		var kills          = data.kills          || 0; // deprecated
		var killOthers     = data.killOthers     || 0; // deprecated
		var spikes         = data.spikes         || 0;
		var tips           = data.tips           || 0;
		var downBallHits   = data.downBallHits   || 0;
		var dumps          = data.dumps          || 0;
		var oppBlockErrors = data.oppBlockErrors || 0;
		var oppDigErrors   = data.oppDigErrors   || 0;
		var oppDerived     = data.oppDerived     || 0; // combo for block/dig for individuals

		return (kills + killOthers + tips + downBallHits + spikes + dumps + oppBlockErrors + oppDigErrors + oppDerived);
	};

	var calcKillErrors = function (data) {
		var spikeErrors         = data.spikeErrors         || 0;
		var errorsTip           = data.tipErrors           || 0;
		var downBallHitErrors   = data.downBallHitErrors   || 0;
		var dumpErrors          = data.dumpErrors          || 0;
		var backRowAttackFaults = data.backRowAttackFaults || 0;

		return (spikeErrors + errorsTip + downBallHitErrors + dumpErrors + backRowAttackFaults);
	};

	var calcReceiveErrors = function (data) {
		var receiveErrors  = data.receiveErrors || 0;
		var oppAces        = data.oppAces       || 0;
		var oppDerived     = data.oppDerived    || 0;

		return (receiveErrors + oppAces + oppDerived);
	};

	var calcDigAttempts = function (digs, digErrors) {
		return digs + digErrors;
	};

	var calcDigAttemptsPerGame = function (digAttempts, gamesPlayed) {
		return calcPerGame(digAttempts, gamesPlayed);
	};

	var calcDigPercent = function (digs, digAttempts) {
		return calcPercent(digs, digAttempts);
	};

	var calcBlocks = function (data, opts) {
		var blockSolos   = data.blockSolos || 0;
		var blockAssists = data.blockAssists || 0;

		if (typeof opts !== "object") {
			opts = {};
		}

		if (opts.blockAssistsHalfValue) {
			return blockSolos + (blockAssists / 2);
		} else {
			return blockSolos + blockAssists;
		}
	};

	var calcBlockAttempts = function (data) {
		var blockSolos        = data.blockSolos        || 0;
		var blockAssists      = data.blockAssists      || 0;
		var blocksStillInPlay = data.blocksStillInPlay || 0;
		var blockErrors       = data.blockErrors       || 0;

		return blockSolos + blockAssists + blocksStillInPlay + blockErrors;
	};

	var calcBlockPercent = function (data) {
		var blockSolos    = data.blockSolos    || 0;
		var blockAssists  = data.blockAssists  || 0;
		var blockAttempts = data.blockAttempts || 0;

		return calcPercent(blockSolos + blockAssists, blockAttempts);
	};

	var calcHittingEfficiency = function (data) {
		var kills        = data.kills;
		var killErrors   = data.killErrors;
		var killAttempts = data.killAttempts;

		var netKills = kills - killErrors;

		// error handling
		if (kills + killErrors > killAttempts) {
			return INVALID_STAT;
		}

		if (killAttempts > 0) {
			return ((netKills) / killAttempts).toFixed(3);
		} else {
			return NOT_AVAILABLE;
		}
	};

	return {
		NOT_AVAILABLE          : NOT_AVAILABLE,
		INVALID_STAT           : INVALID_STAT,
		init                   : init,
		calc                   : calc,
		calcSideOutPercent     : calcSideOutPercent,
		calcPointScorePercent  : calcPointScorePercent,
		calcPassRating         : calcPassRating,
		calcPassErrorPercent   : calcPassErrorPercent,
		calcKills              : calcKills,
		calcKillErrors         : calcKillErrors,
		calcDigAttempts        : calcDigAttempts,
		calcDigAttemptsPerGame : calcDigAttemptsPerGame,
		calcDigPercent         : calcDigPercent,
		calcBlocks             : calcBlocks,
		calcBlockAttempts      : calcBlockAttempts,
		calcBlockPercent       : calcBlockPercent,
		calcHittingEfficiency  : calcHittingEfficiency,
		calcReceiveErrors      : calcReceiveErrors,
		calcServeRating        : calcServeRating,
		// TODO: these actually don't belong in stats.  Stats
		// should be pure stat related.
		getColumnKey           : getColumnKey,
		getColumnAgg           : getColumnAgg,
		getColumnWho           : getColumnWho,
		getColumnFmt           : getColumnFmt
	};

})();
